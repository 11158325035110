import { transition, trigger, useAnimation } from '@angular/animations'
import { CommonModule } from '@angular/common'
import {
  Component,
  computed,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { ErrorObject, HttpRequestError } from '@naturalcycles/js-lib'
import {
  AuthMode,
  EmailInput,
  ErrorCode,
  Experiment,
  getBucket,
  LoginProvider,
} from '@naturalcycles/shared'
import {
  AccountService,
  MIN_LOGIN_PASSWORD_LENGTH,
  MIN_NEW_PASSWORD_LENGTH,
} from '@src/app/core/services/account.service'
import { MixpanelService } from '@src/app/core/services/analytics/mixpanel.service'
import { ModalService } from '@src/app/core/services/modal.service'
import { getState, SignalStore } from '@src/app/core/store/signalStore'
import { fadeAnimation } from '@src/app/core/util/animations.util'
import { logger } from '@src/app/core/util/log.util'
import { emailValidator } from '@src/app/core/util/validators.util'
import { SharedModule } from '@src/app/shared/shared.module'
import { MixpanelEvent } from '@src/app/shared/typings/analytics'
import { AuthProviderType } from '@src/app/shared/typings/enum/auth'
import { EmailAuthProviderInput } from '@src/app/shared/typings/interfaces/user-auth'
import { env } from '@src/environments/environment'
import { debounceTime, distinctUntilChanged, Subscription, tap } from 'rxjs'
import { ButtonComponentModule } from '../../../button/button.component.module'
import { TextInputComponentModule } from '../../../input/text/text-input.component.module'
import { AlertComponent } from '../../../modals/alert.component'
import { ForgotPasswordComponent } from '../../../modals/forgot-password/forgot-password.component'
import { VerifyEmailComponent } from '../../../modals/verify-email.component'
import { AuthProviderComponent } from '../auth-provider.component'

@Component({
  selector: 'app-email-auth',
  templateUrl: './email-auth.component.html',
  animations: [trigger('fadeAnimation', [transition('* => *', [useAnimation(fadeAnimation)])])],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponentModule,
    TextInputComponentModule,
  ],
  styleUrls: ['./email-auth.component.scss'],
})
export class EmailAuthComponent
  extends AuthProviderComponent
  implements OnInit, OnDestroy, OnChanges
{
  store = inject(SignalStore)
  private accountService = inject(AccountService)
  private modalService = inject(ModalService)
  private mixpanelService = inject(MixpanelService)

  @Input()
  public class = ''

  @Input()
  public accountAlreadyExists = false

  @Input()
  public showAuthForm = false

  @Input()
  public authError: ErrorObject<HttpRequestError> | undefined

  public loading = false

  private subscriptions: Subscription[] = []

  public form = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, emailValidator()],
    }),
    password: new FormControl(''),
  })

  public textInputType: 'password' | 'text' = 'password'
  public AuthMode = AuthMode

  protected isTest358 = computed(
    () => getBucket(this.store.$experiment().assignments[Experiment.EARLY_LEAD_CAPTURE]) === 'test',
  )

  ngOnInit(): void {
    const newsletterEmail = this.store.$quiz().data?.newsletter
    if (this.isTest358() && newsletterEmail) {
      this.form.get('email')?.setValue(newsletterEmail)
    }
    this.initForm()
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) sub.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['authError'] && !changes['authError'].isFirstChange()) {
      this.handleLoginError(this.authError)
    }
    if (changes['provider'] && !changes['provider'].isFirstChange()) {
      this.showAuthForm = this.provider === AuthProviderType.email
    }
  }

  private initForm(): void {
    const { account } = getState()
    if (account.email) {
      this.form.get('email')?.setValue(account.email)
    }

    this.subscriptions.push(
      (this.form.controls.email as FormControl<string>).valueChanges
        .pipe(
          distinctUntilChanged(),
          tap((email: string) => {
            email = email?.toLowerCase() || ''
            this.store.$account.update(s => ({ ...s, email }))
          }),
          debounceTime(500),
        )
        .subscribe(),
    )
    this.form.controls['password'].setValidators([
      Validators.required,
      Validators.minLength(
        this.mode === AuthMode.login ? MIN_LOGIN_PASSWORD_LENGTH : MIN_NEW_PASSWORD_LENGTH,
      ),
    ])
  }

  public toggleShowForm(): void {
    this.showAuthForm = !this.showAuthForm
    this.didChangeProvider.emit(this.showAuthForm ? AuthProviderType.email : AuthProviderType.none)
  }

  public togglePasswordVisibility(): void {
    this.textInputType = this.textInputType === 'password' ? 'text' : 'password'
  }

  public async onSignin(): Promise<void> {
    try {
      this.loading = true
      await this.handleSigningIn()
    } finally {
      this.loading = false
    }
  }

  private async handleSigningIn(): Promise<void> {
    const email = this.form.controls['email'].value!
    const pw = this.form.controls['password'].value!
    const accountAlreadyExists = await this.accountService.shouldLoginUser({
      provider: LoginProvider.EMAIL,
      email,
    })

    const input: EmailAuthProviderInput = {
      type: AuthProviderType.email,
      accountAlreadyExists,
      email,
      pw,
    }

    // If the user is trying to log in with an email that doesn't exist, we don't need to validate the password
    if (!accountAlreadyExists && this.mode === AuthMode.login) {
      return this.authResult.emit({
        mode: this.mode,
        type: input.type,
        input,
      })
    }

    if (!accountAlreadyExists) {
      const { emailValid, pwValid } = await this.accountService.verifyCredentials(email, pw)

      if (!pwValid) {
        void this.mixpanelService.trackEvent(MixpanelEvent.ExcludedPassword)
        return this.handleExcludedPassword()
      }

      // emailValid === undefined means 'unknown' validity -- allow the user to proceed
      if (emailValid === false) {
        return this.handleNonDeliverableEmail(input)
      }
    } else {
      const pwValid = pw.length >= MIN_LOGIN_PASSWORD_LENGTH
      const emailValidationErrors = emailValidator()(this.form.controls['email'])
      const emailValid = emailValidationErrors === null

      // The user typed in a password below the minimum length for existing accounts
      if (!(pwValid && emailValid)) {
        void this.mixpanelService.trackEvent(MixpanelEvent.InvalidLogin)
        return this.handleInvalidLogin()
      }
    }

    this.authResult.emit({
      mode: this.mode,
      type: input.type,
      input,
    })
  }

  private handleLoginError(err?: ErrorObject<HttpRequestError>): void {
    if (!err) return

    this.form.get('password')?.setValue('')
    if (err.cause?.data.code === ErrorCode.INVALID_PASSWORD) {
      this.form.get('password')?.setErrors({ incorrect: true })
    }
  }

  public async forgotPassword(email: string): Promise<void> {
    const emailInput = { email }
    return await this.modalService.show({
      component: ForgotPasswordComponent,
      modalName: 'ForgotPasswordModal',
      titleKey: 'intro-btn-forgotPassword',
      api: emailInput,
      controls: [
        { key: 'btn-cancel' },
        {
          key: 'btn-send',
          action: () => {
            this.form.controls.email.setValue(emailInput.email)
            return this.resetPassword(emailInput.email)
          },
        },
      ],
    })
  }

  private async resetPassword(email: string): Promise<void> {
    const body: EmailInput = { email }

    await this.accountService.resetPassword(body)

    return await this.modalService.show({
      component: AlertComponent,
      modalName: 'ResetPasswordModal',
      titleKey: 'reset-password-title',
      api: {
        message: 'reset-password-email-sent',
      },
    })
  }

  private handleNonDeliverableEmail(input: EmailAuthProviderInput): void {
    const emailInput: EmailInput = { email: input.email }
    void this.mixpanelService.trackEvent(MixpanelEvent.InvalidEmail)

    void this.modalService.show({
      component: VerifyEmailComponent,
      titleKey: 'cant-deliver-email',
      hideCloseButton: true,
      api: emailInput,
      controls: [
        {
          key: 'btn-ignore',
          action: () => {
            void this.mixpanelService.trackEvent(MixpanelEvent.IgnoredEmailVerification)
            this.authResult.emit({
              mode: this.mode,
              type: input.type,
              input,
            })
          },
        },
        {
          key: 'btn-confirm',
          action: () => {
            this.form.controls['email'].setValue(emailInput.email.toLowerCase())
            void this.onSignin()
          },
        },
      ],
    })
  }

  private handleExcludedPassword(): void {
    void this.modalService.show({
      component: AlertComponent,
      modalName: 'ExcludedPasswordModal',
      api: {
        message: 'auth-excluded-password-msg',
      },
    })
  }

  private handleInvalidLogin(): void {
    void this.modalService.show({
      component: AlertComponent,
      modalName: 'InvalidLoginModal',
      api: {
        // This message reads: Sorry, we couldn't find that email and/or password
        // The name of the key is a little misleading, but it fits well here
        message: 'incorrect-email-txt',
      },
    })
  }

  // EASTER EGG
  public createTestAccount(): void {
    if (env.prod) return

    const email = 'test' + Math.random().toString(36).slice(8) + '@nc.com'
    const password = '12345678'
    logger.log('random test user:\n' + email)

    this.form.setValue({
      email,
      password,
    })

    void this.onSignin()
  }
}
